import * as React from 'react';
import { Link } from 'gatsby';
import ArrowUpIcon from '!svg-react-loader!../images/vectors/arrow_up.svg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useSwipeable } from 'react-swipeable';
import { useSpring, animated } from 'react-spring';

import './index.scss';


const HomePage = ({ currentPage, setNextPage }) => {
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log('User Swiped!', eventData);
      setNextPage('one');
    }
  });
  return (
    <div
      {...handlers}
      style={{ height: 300, backgroundColor: 'aqua' }}
    >
      <div>MedTrail Home</div>
    </div>
  );
};

const PageOne = ({ currentPage, setNextPage }) => {
  const isPageActivated = currentPage === 'one';
  const contentProps = useSpring({
    opacity: isPageActivated ? 1 : 0,
    marginTop: isPageActivated ? 0 : 500
  });
  if(isPageActivated) {
    return (
      <animated.div style={contentProps}>
        <div style={{ height: 300, backgroundColor: 'red' }}>
          MedTrail Page 2
        </div>
      </animated.div>
    );
  } else {
    return null;
  }

};

const SwipeablePage = () => {
  const config = {};
  const [ currentPage, setNextPage ] = React.useState("home");
  // const handlers = useSwipeable({
  //   onSwiped: (eventData) => {
  //     console.log('User Swiped!', eventData);
  //   },
  //   ...config
  // });
  // const contentProps = useSpring({
  //   opacity: swipedStatus ? 1 : 0,
  //   marginTop: swipedStatus ? 0 : 500
  // });
  return (
    <div>
      <SEO title="Home" />
      {currentPage === 'home' ? (<HomePage currentPage={currentPage} setNextPage={setNextPage} />) : null}
      <PageOne setNextPage={setNextPage} currentPage={currentPage} />
    </div>
  );
};

export default SwipeablePage;
